<template>
    <v-card flat tile color="secondary" class="pb-3">
        <div v-if="showTom" class="px-4 pt-3">
            <v-alert v-if="companyTom" type="info" icon="mdi-information-outline" prominent class="text-body-1 mt-0" outlined>
                <span
                    v-if="companyTom.isSet"
                    v-html="
                        $t('vvtTom.processingActivityTom.isSetDescription', {
                            companyName: company.title,
                            companyCity: company.city,
                            companyCountry: company.countryName,
                        })
                    "
                />
                <div v-else>
                    <div v-html="$t('vvtTom.processingActivityTom.notSetDescription', {
                            companyName: company.title,
                            companyCity: company.city,
                            companyCountry: company.countryName,
                        })" />
                </div>
                <v-tooltip
                    v-if="companyTom && companyTom.isSet"
                    bottom
                    color="primary"
                >
                    <template v-slot:activator="{ on }">
                        <v-btn
                            :disabled="!canEdit"
                            v-on="on"
                            color="primary"
                            :fab="$vuetify.breakpoint.smAndDown"
                            :small="$vuetify.breakpoint.smAndDown"
                            @click="resetTomConfirmation = true"
                            class="ml-3"
                        >
                            <v-icon :left="$vuetify.breakpoint.mdAndUp" v-text="'mdi-undo-variant'" />
                            <span v-if="$vuetify.breakpoint.mdAndUp" v-t="'vvtTom.reset.buttonLabel'" />
                        </v-btn>
                    </template>
                    <span
                        v-if="$vuetify.breakpoint.mdAndUp"
                        v-t="'vvtTom.reset.buttonTooltip'" />
                </v-tooltip>
            </v-alert>
            <v-progress-linear
                :active="$wait.is('tomLoader')"
                indeterminate
                color="primary"
            ></v-progress-linear>
            <div class="relative" :class="{'pt-3': $wait.is('tomLoader')}">
                <div v-if="!$wait.is('tomLoader')">
                    <v-expansion-panels class="pb-3" accordion>
                        <PseudonymisationMeasures
                            v-model="pseudonymisationMeasures"
                            :items="pseudonymisationMeasuresItems"
                            items-dispatcher="tom/unshiftItems"
                            @modified.once="onModifiedByUser()"
                            class="tertiary"
                            :disabled="!canEdit"
                            disable-freetext
                        />
                        <EncryptionMeasures
                            v-model="encryptionMeasures"
                            :items="encryptionMeasuresItems"
                            items-dispatcher="tom/unshiftItems"
                            @modified.once="onModifiedByUser()"
                            class="tertiary"
                            :disabled="!canEdit"
                            disable-freetext
                        />
                        <ConfidentialityMeasures
                            v-model="confidentialityMeasures"
                            :accessControlItems="confidentialityMeasuresAccessControlItems"
                            :systemControlItems="confidentialityMeasuresSystemControlItems"
                            :volumeControlItems="confidentialityMeasuresVolumeControlItems"
                            :separationControlItems="confidentialityMeasuresSeparationControlItems"
                            items-dispatcher="tom/unshiftItems"
                            @modified.once="onModifiedByUser()"
                            class="tertiary"
                            :disabled="!canEdit"
                            disable-freetext
                        />
                        <IntegrityMeasures
                            v-model="integrityMeasures"
                            :dataIntegrityGuaranteeItems="integrityMeasuresDataIntegrityGuaranteeItems"
                            :transmissionControlItems="integrityMeasuresTransmissionControlItems"
                            :transportControlItems="integrityMeasuresTransportControlItems"
                            :inputControlItems="integrityMeasuresInputControlItems"
                            items-dispatcher="tom/unshiftItems"
                            @modified.once="onModifiedByUser()"
                            class="tertiary"
                            :disabled="!canEdit"
                            disable-freetext
                        />
                        <AvailabilityAndResilienceMeasures
                            v-model="availabilityAndResilienceMeasures"
                            :availabilityControlItems="availabilityAndResilienceMeasuresAvailabilityControlItems"
                            :recoverabilityItems="availabilityAndResilienceMeasuresRecoverabilityItems"
                            :reliabilityItems="availabilityAndResilienceMeasuresReliabilityItems"
                            items-dispatcher="tom/unshiftItems"
                            @modified.once="onModifiedByUser()"
                            class="tertiary"
                            :disabled="!canEdit"
                            disable-freetext
                        />
                        <EvaluationOfDataProcessingSecurityMeasures
                            v-model="evaluationOfDataProcessingSecurityMeasures"
                            :reviewProcessItems="evaluationOfDataProcessingSecurityMeasuresReviewProcessItems"
                            :orderControlItems="evaluationOfDataProcessingSecurityMeasuresOrderControlItems"
                            items-dispatcher="tom/unshiftItems"
                            @modified.once="onModifiedByUser()"
                            class="tertiary"
                            :disabled="!canEdit"
                            disable-freetext
                        />
                        <FurtherMeasures
                            v-model="furtherMeasures"
                            @modified.once="onModifiedByUser()"
                            class="tertiary"
                            :disabled="!canEdit"
                        />
                    </v-expansion-panels>
                </div>
            </div>
        </div>
        <div class="mb-6 tertiary pa-2" v-else>
            <v-alert class="ma-3" type="info" icon="mdi-information-outline" outlined>
                <span v-t="template ? 'vvtTom.processingActivityTom.templateModeMessage' : 'vvtTom.processingActivityTom.selectCompanyMessage'" />
            </v-alert>
        </div>
        <BaseConfirmationDialog
            v-model="resetTomConfirmation"
            :title="$t('vvtTom.reset.confirmationHeadline')"
            :text="$t('vvtTom.reset.confirmationText')"
            :confirmationText="$t('vvtTom.reset.buttonLabel')"
            :cancelText="$t('cancel')"
            @cancel="resetTomConfirmation = false"
            @confirm="resetTom()"
        />
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import PseudonymisationMeasures from '@/components/vvt/Tom/PseudonymisationMeasures.vue';
import EncryptionMeasures from '@/components/vvt/Tom/EncryptionMeasures.vue';
import ConfidentialityMeasures from '@/components/vvt/Tom/ConfidentialityMeasures.vue';
import IntegrityMeasures from '@/components/vvt/Tom/IntegrityMeasures.vue';
import AvailabilityAndResilienceMeasures from '@/components/vvt/Tom/AvailabilityAndResilienceMeasures.vue';
import EvaluationOfDataProcessingSecurityMeasures from '@/components/vvt/Tom/EvaluationOfDataProcessingSecurityMeasures.vue';
import FurtherMeasures from '@/components/vvt/Tom/FurtherMeasures.vue';

export default {
    name: 'VvtProcessingActivityTom',
    components: {
        PseudonymisationMeasures,
        EncryptionMeasures,
        ConfidentialityMeasures,
        IntegrityMeasures,
        AvailabilityAndResilienceMeasures,
        EvaluationOfDataProcessingSecurityMeasures,
        FurtherMeasures,
    },
    props: {
        formScope: {
            type: String,
            default: null,
        },
        create: {
            type: Boolean,
            default: false,
        },
        template: {
            type: Boolean,
            default: false,
        },
        company: {
            type: [Object, Array],
            default: null,
        },
        editorMode: {
            type: String,
            default: null,
        },
        currentUiLanguage: {
            type: String,
            default: null,
        },
    },
    i18n: {
        messages: {
            en: require('@/locales/vvt/Tom/en.json'),
            de: require('@/locales/vvt/Tom/de.json'),
        },
    },
    data() {
        return {
            resetTomConfirmation: false,
        };
    },
    computed: {
        ...mapGetters({
            get: 'processingActivityModel/getProperty',
            workflowMode: 'processingActivityModel/getWorkflowModeState',
            getCompanyTom: 'companyTom/getByCompanyId',
            pseudonymisationMeasuresItems: 'tom/getPseudonymisationMeasures',
            encryptionMeasuresItems: 'tom/getEncryptionMeasures',
            confidentialityMeasuresAccessControlItems:
                'tom/getConfidentialityMeasuresAccessControl',
            confidentialityMeasuresSystemControlItems:
                'tom/getConfidentialityMeasuresSystemControl',
            confidentialityMeasuresVolumeControlItems:
                'tom/getConfidentialityMeasuresVolumeControl',
            confidentialityMeasuresSeparationControlItems:
                'tom/getConfidentialityMeasuresSeparationControl',
            integrityMeasuresDataIntegrityGuaranteeItems:
                'tom/getIntegrityMeasuresDataIntegrityGuarantee',
            integrityMeasuresTransmissionControlItems:
                'tom/getIntegrityMeasuresTransmissionControl',
            integrityMeasuresTransportControlItems:
                'tom/getIntegrityMeasuresTransportControl',
            integrityMeasuresInputControlItems:
                'tom/getIntegrityMeasuresInputControl',
            availabilityAndResilienceMeasuresAvailabilityControlItems:
                'tom/getAvailabilityAndResilienceMeasuresAvailabilityControl',
            availabilityAndResilienceMeasuresRecoverabilityItems:
                'tom/getAvailabilityAndResilienceMeasuresRecoverability',
            availabilityAndResilienceMeasuresReliabilityItems:
                'tom/getAvailabilityAndResilienceMeasuresReliability',
            evaluationOfDataProcessingSecurityMeasuresReviewProcessItems:
                'tom/getEvaluationOfDataProcessingSecurityMeasuresReviewProcess',
            evaluationOfDataProcessingSecurityMeasuresOrderControlItems:
                'tom/getEvaluationOfDataProcessingSecurityMeasuresOrderControl',
        }),
        companyTom () {
            return this.get('tom');
        },
        canEdit () {
            return this.get('canEdit');
        },
        showTom() {
            if (
                (this.editorMode === 'singleEditMode' ||
                    this.editorMode === 'singleCreationMode') &&
                this.processingActivityTom
            ) {
                return true;
            }
            return false;
        },
        processingActivityTom() {
            if (this.company && this.company.id) {
                if (this.companyTom && this.companyTom.isSet) {
                    return this.companyTom;
                }
                return this.getCompanyTom(this.company.id);
            }
            return null;
        },
        furtherMeasures: {
            get () {
                return this.processingActivityTom ? this.processingActivityTom.furtherMeasures : null;
            },
            set (val) {
                this.changeProperty('furtherMeasures', val);
            }
        },
        evaluationOfDataProcessingSecurityMeasures: {
            get () {
                return this.processingActivityTom
                        ? this.processingActivityTom.evaluationOfDataProcessingSecurityMeasures
                        : {
                            reviewProcess: [],
                            orderControl: [],
                        };
            },
            set (val) {
                this.changeProperty('evaluationOfDataProcessingSecurityMeasures', val);
            }
        },
        availabilityAndResilienceMeasures: {
            get () {
                return this.processingActivityTom
                        ? this.processingActivityTom.availabilityAndResilienceMeasures
                        : {
                            availabilityControl: [],
                            recoverability: [],
                            reliability: [],
                        };
            },
            set (val) {
                this.changeProperty('availabilityAndResilienceMeasures', val);
            }
        },
        integrityMeasures: {
            get () {
                return this.processingActivityTom
                        ? this.processingActivityTom.integrityMeasures
                        : {
                            dataIntegrityGuarantee: [],
                            transmissionControl: [],
                            transportControl: [],
                            inputControl: [],
                        };
            },
            set (val) {
                this.changeProperty('integrityMeasures', val);
            }
        },
        confidentialityMeasures: {
            get () {
                return this.processingActivityTom
                    ? this.processingActivityTom.confidentialityMeasures
                    : {
                        accessControl: [],
                        systemControl: [],
                        volumeControl: [],
                        separationControl: [],
                    };
            },
            set (val) {
                this.changeProperty('confidentialityMeasures', val);
            }
        },
        encryptionMeasures: {
            get () {
                return this.processingActivityTom ? this.processingActivityTom.encryptionMeasures : null;
            },
            set (val) {
                this.changeProperty('encryptionMeasures', val);
            }
        },
        pseudonymisationMeasures: {
            get () {
                return this.processingActivityTom ? this.processingActivityTom.pseudonymisationMeasures : null;
            },
            set (val) {
                this.changeProperty('pseudonymisationMeasures', val);
            }
        },
    },
    methods: {
        ...mapActions({
            set: 'processingActivityModel/setProperty',
            setTomProperty: 'processingActivityModel/setTomProperty',
            fetchTomItems: 'tom/fetchItems',
            fetchCompanyTomById: 'companyTom/fetchById',
        }),
        changeProperty(property, value) {
            this.setTomProperty({
                property: property,
                data: value,
            });
        },
        onModifiedByUser() {
            this.changeProperty('isSet', true);
        },
        async fetchTom() {
            this.$wait.start('tomLoader');
            await this.fetchTomItems({
                forcedLanguage: this.currentUiLanguage,
            });
            if (
                this.company && this.company.id
            ) {
                await this.fetchCompanyTomById({
                    id: this.company.id,
                    forcedLanguage: this.currentUiLanguage,
                });
            }
            this.$wait.end('tomLoader');
        },
        resetTom() {
            this.changeProperty('isSet', false);
            this.fetchTom();
        },
    },
    created() {
        this.fetchTom();
    },
    watch: {
        company(to, from) {
            if (to && (!from || from.id !== to.id)) {
                this.resetTom();
            }
        },
    },
};
</script>
