<template>
    <v-card flat tile color="secondary">
        <form
            @submit.prevent="validateForm(formScope)"
            :data-vv-scope="formScope"
            :ref="formScope"
        >
            <v-tabs
                color="primary"
                class="elevation-3"
                background-color="transparent"
                icons-and-text
                v-model="tomTab">
                <v-tabs-slider color="primary"></v-tabs-slider>

                <v-tab v-if="getCompanyGroupSettings('useCompanyTomInRecord')" href="#companyTom">
                    <span>{{ $t('vvtTom.companyTomTabTitle') }}</span
                    ><v-icon>mdi-security</v-icon>
                </v-tab>

                <v-tab v-if="getCompanyGroupSettings('useToolTomInRecord')" href="#toolTom">
                    <span>{{ $t('vvtTom.toolTomTabTitle') }}</span
                    ><v-icon>mdi-server-security</v-icon>
                </v-tab>
                
                <v-tab-item v-if="getCompanyGroupSettings('useCompanyTomInRecord')" :value="'companyTom'">
                    <ProcessingActivityTom
                        :create="create"
                        :template="template"
                        :formScope="formScope"
                        :editorMode="editorMode"
                        :company="company"
                        :currentUiLanguage="currentUiLanguage"
                        @help-mode="$emit('help-mode', $event)"
                    />
                </v-tab-item>

                <v-tab-item v-if="getCompanyGroupSettings('useToolTomInRecord')" :value="'toolTom'">
                    <v-card flat tile color="secondary">
                        <div class="px-1 pt-3 pb-3">
                            <ToolTom 
                                :useVendor="getCompanyGroupSettings('useVendor')"
                                @help-mode="$emit('help-mode', $event)"
                            />
                        </div>
                    </v-card>
                </v-tab-item>
            </v-tabs>
        </form>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import ProcessingActivityTom from './Tom/Company';
import ToolTom from './Tom/Tools';

export default {
    name: 'Step6',
    components: {
        ProcessingActivityTom,
        ToolTom
    },
    i18n: {
        messages: {
            en: require('@/locales/vvt/Tom/en.json'),
            de: require('@/locales/vvt/Tom/de.json'),
        },
    },
    props: {
        create: {
            type: Boolean,
            default: false,
        },
        template: {
            type: Boolean,
            default: false,
        },
        company: {
            type: [Object, Array],
            default: null,
        },
        editorMode: {
            type: String,
            default: null,
        },
        formScope: {
            type: String,
            default: null
        },
        currentUiLanguage: {
            type: String,
            default: null,
        },
    },
    
    computed: {
        ...mapGetters({
            get: 'processingActivityModel/getProperty',
            getCompanyGroupSettings: 'companyGroupSettings/get'
        })
    },
    data() {
        return {
            tomTab: 'companyTom',
        };
    }
};
</script>
